<template>
  <ul class="grid chk">
    <!-- <li>
      <input type="checkbox" id="chk_all" v-model="checkedValues" value="00" @click="allCheck">
      <label for="chk_all">전체</label>
    </li> -->
    <li>
      <input type="checkbox" id="chk1" v-model="checkedValues" value="01" @change="clickFunc">
      <label for="chk1">{{ $t('msg.MYIN030T010.013') }}</label>
    </li>
    <li>
      <input type="checkbox" id="chk2" v-model="checkedValues" value="02,04" @change="clickFunc">
      <label for="chk2">{{ $t('msg.MYIN030T010.014') }}</label>
    </li>
    <li>
      <input type="checkbox" id="chk3" v-model="checkedValues" value="03" @change="clickFunc">
      <label for="chk3">{{ $t('msg.MYIN030T010.015') }}</label>
    </li>
  </ul>
</template>

<script>
export default {
 props: ['removeValue'],
    data () {
        return {
            checkedValues: ['01', '02,04', '03'],
            allChecked: [],
            isAllChecked: false
        }
    },
    mounted () {
      //this.allCheck()
    },
    methods: {
        clickFunc (event) {
            let checkedVal = {
                targetChecked: event.target.value,
                checkedArr: this.checkedValues,
                checkAll: false
            }

           this.$emit('checkClick', checkedVal)
        },
        allCheck () {
            if (!this.isAllChecked) {
                for (let i = 1; i < 4; i++) {
                    if (i !== 2) {
                      if (i === 1) {
                        this.checkedValues.push('00')
                      }
                      i = '0' + i
                      this.checkedValues.push(i)
                    }
                }
                // 차감 코드는 '02','04'
                this.checkedValues.push('02,04')

               this.isAllChecked = true
            } else {
                this.checkedValues = []
                this.isAllChecked = false
            }
             this.$emit('checkClick', this.checkedValues)
        }
    }
}
</script>

<style scoped>
ul.grid {
  margin-left: 10px;
}
</style>
